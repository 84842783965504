import React from 'react';
import '../App.css'

const Services = () => {
  return (
    <div className="services">
      <div className="services-background">
        <div className="text-container">
          <h1>Our Services</h1>
          <ul>
          <p>In essence, C-STAR acts as a problem-solver in the ever-evolving space industry, fostering mission success for our clients.</p>
            <li>Satellite Acquisition: Guiding clients through the entire acquisition process.</li>
            <li>Operations and Control: Operating and maintaining satellites and ground stations.</li>
            <li>Earth Ground Stations: Designing and managing custom ground stations.</li>
            <li>Logistics: Efficient oversight of transportation, warehousing, and distribution.</li>
            <li>Project Management: Ensuring compliance and delivering projects on time.</li>
            <li>Information Technology: Providing tailored IT solutions.</li>
            <li>Cybersecurity: Comprehensive cybersecurity services to protect space assets.</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Services;

