import React from 'react';
import '../App.css'
const Home = () => {
  return (
    <div className="home">
      <div className='home-background'>
        <div className='home-text-container'>
          <h1>Welcome to C-STAR an Aerospace Services Company</h1>
          <p>Fostering mission success for our clients in the ever-evolving space industry.</p>
          <p>C-STAR prioritizes mission assurance, security, and compliance, tailoring solutions to deliver reliable, cost-effective, and on-time results for government agencies in the space domain.</p>
        </div>
      </div>
    </div>
  );
}

export default Home;


