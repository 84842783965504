import React from 'react';
import '../App.css'
const Contact = () => {
  return (
    <div className="contact">
      <div className='contact-background'>
        <div className='text-container'>
        <h1>Contact Us</h1>
        <p><strong>Why Choose C-STAR?</strong> We prioritize your success, have a proven track record, offer end-to-end solutions, possess expertise and experience, prioritize security and compliance, and provide flexibility and customization.</p>
        <p>Get in touch with us to learn more about our services.</p>
        <p>michael.neil2@gmail.com</p>
        </div>
      </div>
    </div>
  );
}
export default Contact;