import React from 'react';
import '../App.css'
const About = () => {
  return (
    <div className="about">
      <div className='about-background'>
        <div className="text-container">
        <h1>About Us</h1>
          <p>C-STAR is an aerospace services firm based out of Huntsville, Alabama. Founded by veterans, we aim to share the knowledge and experiences gained throughout our military service and beyond.</p>
          <p>We specialize in:</p>
          <ul>
            <li>Assisting in the acquisition life cycle of satellites</li>
            <li>Operating and maintaining satellites and other space assets</li>
            <li>Designing, constructing, and maintaining ground stations</li>
            <li>Managing transportation, warehousing, and logistics services</li>
            <li>Planning, executing, and managing space-related projects</li>
            <li>Providing information technology and cybersecurity services</li>
          </ul>
          <p><strong>C-STAR’s services address several pain points for our customers, particularly the Department of Defense (DOD):</strong> We simplify the complexities of space operations, bridge expertise gaps, ensure compliance, optimize costs, and focus on mission success.</p>
        </div>
      </div>
    </div>
  );
}

export default About;


